import React, {Component} from 'react';
import {HeaderComponent} from './HeaderComponent';
import {FooterComponent} from './FooterComponent';
import { ToastContainer } from 'react-toastify';

export class LayoutComponent extends Component {
    render() {
        return (
            <>
                <nav className="page-header navbar fixed-top navbar-light text-light"  style={{backgroundColor: "black"}}>
                    <div className="container-fluid">
                        <HeaderComponent/>
                    </div>
                </nav>

                <div className="page-content p-3">
                    {this.props.children}
                </div>

                <nav className="page-footer navbar fixed-bottom navbar-light text-light" style={{backgroundColor: "black"}}>
                    <div className="container-fluid">
                        <FooterComponent/>
                    </div>
                </nav>

                <ToastContainer />
            </>
        );
    }
}
